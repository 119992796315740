.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  /* height: 100vh; */
}
.wrapper-body {
  width: 400px;
  height: 100vh;
  overflow: hidden;
  text-align: center;
  border: 1px solid #dfdfdf;
  background-color: #f0f3f4;
  position: relative;
}
.wrapper-body.wrapper-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}
.wrapper-body.wrapper-preview-section {
  background: linear-gradient(to bottom, #0047ab, #CE4A80);
}
.form-smash-block input {
  font-family: "Nunito", sans-serif;
  font-size: 14px !important;
  color: #334856 !important;
  padding: 0 5px 0 15px !important;
  background-color: #ffffff !important;
  border: 1px solid transparent !important;
  box-shadow: 0px 4px 16px #455b6314 !important;
  border-radius: 8px !important;
  margin-top: 30px !important;
  width: -webkit-fill-available !important;
  width: -moz-available !important;
}
.form-smash-block textarea {
  font-family: "Nunito", sans-serif;
  font-size: 14px !important;
  color: #334856 !important;
  padding: 15px 5px 5px 15px !important;
  background-color: #ffffff !important;
  border: 1px solid transparent !important;
  box-shadow: 0px 4px 16px #455b6314 !important;
  border-radius: 8px !important;
  margin-top: 30px !important;
  width: -webkit-fill-available !important;
  width: -moz-available !important;
  height: auto;
  resize: none;
}
.form-smash-block input:focus,
.form-smash-block textarea:focus {
  border: 1px solid #0047ab !important;
}
.form-smash-block label {
  color: #334856 !important;
  font-size: 18px !important;
  font-weight: bold;
  padding-left: 15px;
  top: -10px;
  transform-origin: unset !important;
  transform: initial !important;
}
.form-smash-block {
  float: left;
  width: 100%;
}
.form-smash-block span.lever {
  background-color: #acacac;
  margin: 0 !important;
  height: 30px !important;
  width: 55px !important;
}
.form-smash-block .switch label .lever:before,
.form-smash-block .switch label .lever:after {
  width: 28px;
  height: 28px;
  left: 1px;
  top: 1px;
}
.form-smash-block .switch label input[type="checkbox"]:checked + .lever:after,
.form-smash-block .switch label .lever:after {
  background-color: #ffffff;
}
.form-smash-block .switch label input[type="checkbox"]:checked + .lever:before,
.form-smash-block .switch label input[type="checkbox"]:checked + .lever:after {
  left: 26px;
}
.form-smash-block .switch label input[type="checkbox"]:checked + .lever {
  background-color: #CE4A80;
}
.form-smash-block.timer-text-switch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px;
}
.form-smash-block.timer-text-switch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px;
  border-top: 1px solid #d8d8d8;
  margin: 10px 0px 5px;
  padding: 15px 0px 0px;
}
.generated-link-block.form-smash-block input {
  margin-top: 0px !important;
  font-size: 18px !important;
  font-family: "Raleway", sans-serif;
  padding-left: 38px !important;
}
.generated-link-block.form-smash-block {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}
.generated-link-block.form-smash-block ::-webkit-input-placeholder {
  color: #3b3c49;
}
.generated-link-block.form-smash-block :-ms-input-placeholder {
  color: #8b8c94;
}
.generated-link-block.form-smash-block ::placeholder {
  color: #8b8c94;
}
.create-smash-block {
  background-color: #ffffff;
  width: 100%;
  box-shadow: 0px -3px 6px #00000029;
}
.smash-block {
  float: left;
  width: 100%;
}
.form-smash-block.preview-thankyou-block .input-field {
  margin: 0;
}
h6.smash-headerh1 {
  color: #324755;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.smash-header {
  height: 100px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #dfdfdf;
  padding: 0 20px;
}
span.header-icon {
  font-weight: bold;
  font-size: 18px;
  display: flex;
  align-items: center;
  text-align: left;
  word-break: break-word;
}
span.header-icon i {
  font-size: xx-large;
  padding-right: 25px;
  position: relative;
  top: -1px;
  color: #324755;
}
span.header-breadcrumb {
  font-size: 16px;
  font-weight: 600;
  font-family: "Raleway", sans-serif;
  cursor: pointer;
}
span.header-breadcrumb a {
  color: #212430;
}
.smash-body {
  padding: 30px 20px 70px 20px;
  float: left;
  width: 100%;
  height: calc(100vh - 154px);
}
.smash-body.smash-body-participant {
  background-color: #ffffff;
}
.smash-lists-block.preview-result-detail-block > h5 {
  color: #ffffff;
}
.smash-lists-block {
  text-align: left;
}
.smash-lists-block > h5 {
  font-size: 18px;
  font-weight: bold;
  color: #334856;
  margin: 0;
}
.smash-lists-block.smash-detail-block {
  display: flex;
  justify-content: space-between;
}
span.smash-state-span {
  font-size: 12px;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  background-color: #21e9a5;
  color: #ffffff;
  border-radius: 4px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.smash-detail-div {
  background-color: #ffffff;
  padding: 20px 15px;
  border-radius: 8px;
  box-shadow: 0px 0px 4px #00000029;
  margin: 20px 0px 25px;
  font-family: "Raleway", sans-serif;
}
.smash-detail-div.smash-detail-div-participant {
  box-shadow: 0px 4px 6px #455b6314;
  border-radius: 12px;
}
.smash-detail-items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
.ranked-items h4 {
  font-size: 15px;
  font-weight: bold;
  color: #21e9a5;
  margin: 0;
  font-family: "Raleway", sans-serif;
  display: inline;
  width: 45%;
  float: left;
}
.ranked-items p {
  font-size: 11px;
  font-weight: 500;
  margin: 0;
  width: 55%;
  float: left;
}
.ranked-items {
  padding: 0 10px 0 0;
  width: 100%;
}
.ranked-item-details {
  display: flex;
  flex-flow: row;
  padding-bottom: 7px;
}
.ranked-items.non-ranked-items {
  position: relative;
}
.ranked-items.non-ranked-items h4 {
  width: 100%;
  text-align: center;
}
.ranked-items.non-ranked-items p {
  position: relative;
  width: 100%;
  text-align: center;
  margin-top: 7px;
}
/* .ranked-items.non-ranked-items::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 50%;
  background-color: #d8d8d8;
  left: 0;
  top: 20%;
} */
.ranked-items.ranked-items-participant h4 {
  font-size: 20px;
  color: #ffb84d;
}
.ranked-items.ranked-items-participant p {
  color: #78849e;
}
.ranked-items.non-ranked-items {
  position: relative;
}

.ranked-items.non-ranked-items::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  background-color: #d8d8d8;
  left: 0;
  /* top: 20%; */
}
.smash-detail-participants-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d8d8d8;
  margin: 0px 0px 15px 0px;
}
p.participants-no-para {
  font-size: 16px;
  font-weight: 500;
}
p.participants-no-para > span {
  color: #ffb84d;
  padding-right: 5px;
}
span.participants-view-all-span {
  font-size: 14px;
  color: #2f83f9;
  text-decoration: underline;
  font-weight: 500;
  cursor: pointer;
}
.swipe-smash-button-background {
  position: relative;
  background: linear-gradient(116deg, #0047ab, #CE4A80) !important;
  width: 100%;
  height: 36px;
  border: white;
  border-radius: 24px;
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
}
.smash-slide-text {
  color: #ffffff;
  font-size: 14px;
  font-family: "Raleway", sans-serif;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}
.swipe-smash-slider i {
  font-size: 16px;
  color: #0047ab;
  padding: 0;
  margin: 0;
}
.swipe-smash-slider {
  transition: width 0.3s, border-radius 0.3s, height 0.3s;
  position: absolute;
  top: 2px;
  background-color: white;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.swipe-smash-slider.smash-swipe-unlocked {
  transition: all 0.3s;
  right: 2px !important;
  left: auto !important;
  width: 32px;
  height: 32px;
  border-radius: inherit;
}
i.material-icons.generated-link-linkicon {
  position: absolute;
  left: 10px;
  top: 11px;
  transform: rotate(90deg);
  color: #8b8c94;
}
.smash-items-list {
  margin-top: 20px;
}
.smash-items-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 4px #00000029;
  margin-top: 5px;
  padding: 0 15px;
  font-weight: 500;
  font-family: "Raleway", sans-serif;
}
.smash-items-block-item {
  width: 50%;
}
p.smash-items-block-item-name {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  text-align: left;
  color: #212430;
  word-break: break-word;
}
p.smash-items-block-item-name img {
  margin-right: 10px;
}

.smash-items-block p > i {
  color: #d8d8d8;
  font-size: 36px;
  width: 36px;
  height: 36px;
  margin-right: 10px;
}
span.smash-items-block-item-number {
  color: #ffb84d;
  font-size: 20px;
}

.smash-items-block-circle {
  height: 25px;
  width: 25px;
  background-color: #ff7777;
  border-radius: 50%;
  display: inline-block;
}
.yellow-circle {
  background-color: #ffb84d !important;
  color: #ffb84d !important;
}
.green-circle {
  background-color: #21e9a5;
  color: #21e9a5;
}
.yellow-numerical {
  color: #ffb84d !important;
}
.red-numerical {
  color: #ff7777 !important;
}
.green-numerical {
  color: #21e9a5 !important;
}
.swipe-smash-caret {
  background: transparent !important;
  cursor: pointer !important;
}

.swipe-smash-caret-container {
  background: linear-gradient(116deg, #0047ab, #CE4A80) !important;
  cursor: pointer !important;
  height: 20px;
}

span.smash-items-block-item-detail-number {
  color: #191b24;
  font-size: 16px;
  /* padding-top: 0px; */
  white-space: nowrap;
}
.smash-items-block-expand {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 4px #00000029;
  margin-top: 5px;
  padding: 0 15px;
  font-weight: 500;
  font-family: "Raleway", sans-serif;
}

.disableButton {
  color: #A9A9A9 !important;
}
