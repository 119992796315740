.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FAFAFA;
  /* height: 100vh; */
}
.wrapper-body {
  width: 400px;
  height: 100vh;
  overflow: hidden;
  text-align: center;
  border: 1px solid #dfdfdf;
  background-color: #F0F3F4;
  position: relative;

  @media(--phone) {
    width: 100%;
  }
}

.lever {
  &::before {
    width: 28px;
    height: 28px;
    left: 1px;
    top: 1px;
  }

  &::after {
    width: 28px;
    height: 28px;
    left: 1px;
    top: 1px;
    background-color: #ffffff;
  }
}

.switch label input[type=checkbox]:checked+.lever {
  background-color: #CE4AB0;
}

.switch label input[type=checkbox]:checked+.lever:after,  .switch label input[type=checkbox]:checked+.lever:before {
  left: 26px !important;
}

::-webkit-scrollbar
{
  width: 5px;  /* for vertical scrollbars */
  height: 5px; /* for horizontal scrollbars */
}
::-webkit-scrollbar-track
{
  background: rgba(0, 0, 0, 0.1);
}
::-webkit-scrollbar-thumb
{
  background: rgb(141 141 141 / 50%);
}

.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 35px;
  height: 35px;
  position: relative;
  animation: spin 1s linear infinite;
  top: 50%;
  left: calc(50% - 17px);
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Toaster override css */
.Toastify__toast-container {
  width: 360px;
}

.Toastify__toast--info {
  background: #0047AB;
  font-family: 'Nunito', sans-serif;
  border-radius: 6px;
  font-weight: 300;
  bottom: 24px;
}

.Toastify__toast-body {
  padding: var(--m-spacing);
  font-weight: normal !important;
  font-size: var(--h2-font-size);
}

