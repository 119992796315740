.hide {
    height: 3px;
    width: 50%;
    background-color: #D8D8D8;
    z-index: 0;
    position: absolute;
    right: 15px;

    &::before {
        content: '';
        height: 10px;
        width: 10px;
        background-color: #c5c5c5;
        position: absolute;
        left: 0px;
        top: -3.7px;
        border-radius: 100%;
        z-index: -1;
    }

    &::after {
        content: '';
        height: 10px;
        width: 10px;
        background-color: #c5c5c5;
        position: absolute;
        right: 0px;
        top: -3.7px;
        border-radius: 100%;
        z-index: -1;
    }
}

.hide {
    /* display: none; */
}

.hide::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px !important;
    height: 25px !important;
    background: #CE4A80 !important;
    cursor: pointer;
    position: relative;
    top: -5px;
  }
  
.hide::-moz-range-thumb {
    width: 25px !important;
    height: 25px !important;
    background: #CE4A80 !important;
    cursor: pointer;
    top: -5px;
}

.hide::-ms-fill-lower { 
    background: #CE4A80 !important;
}
