.wrapperFlexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: var(--white);
}

.paragraph {
  font-size: var(--l-font-size);
  font-weight: var(--regular);
  margin-top: var(--xxxl-spacing);
}

.header {
  font-size: var(--xxl-spacing);
  font-weight: var(--bold);
}

.responsive {
  margin: 41px 0px 70px;
}

.button {
    background-color: #ffffff;
    box-shadow: 0px 20px 50px #0047AB;
    color: #1B1C20;
    font-weight: 600;
    border-radius: 25px;
    padding: 0px 50px;
    font-family: 'Raleway', sans-serif;
    height: 54px;
    line-height: 54px;
    font-size: 14px;
    text-transform: uppercase;
    border: none;
    cursor: pointer;

    &:hover {
        box-shadow: 0px 20px 50px #0047AB;
        background-color: #ffffff !important;
    }
}
.signupBtn {
  background-color: var(--white);
  box-shadow: 0px 20px 50px #0047ab;
  color: var(--pink);
  font-weight: 700;
  text-transform: capitalize;
  border-radius: 25px;
  padding: 0px 25px;
  background-color: var(--white) !important;
  &:hover {
    box-shadow: 0px 20px 50px var(--blue);
    background-color: var(--white) !important;
  }
}
