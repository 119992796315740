.block {
    float: left;
    width: 100%;
}

.header {
    height: 100px;
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--grey);
    padding: 0 var(--xl-spacing);
}

.h6 {
    color: var(--black);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.icon {
    font-weight: bold;
    font-size: 18px;
    display: flex;
    align-items: center;
    text-align: left;
}

.breadcrumb {
    font-size: var(--h3-font-size);
    font-weight: var(--semi-bold);
    font-family: 'Raleway', sans-serif;
}

.backBtn {
    background-color: transparent !important;
    border: none;
}

.backIcon {
    font-size: 32px;
    padding-right: var(--xxl-spacing);
    position: relative;
    top: -1px;
    color: var(--black);
    cursor: pointer;
}

.body {
    padding: 30px 20px 75px 20px;
    float: left;
    width: 100%;
    height: calc(100vh - 230px);
    overflow-y: auto;
    overflow-x: hidden;
      /* added media query for iphoneSE screen */
  @media screen and (device-aspect-ratio: 375/667) {
    height: calc(100vh - 220px);
  }
}

.footer{
    position: absolute;
    bottom: 0px;
    width: 100%;
    z-index: 1030;
}

.preview{
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: 6%;
}

.buttonGroup {
    width: 100%;
    box-sizing: border-box;
    @media screen and (max-width: 280px) {
           width: 100vw;
       }
}

.next {
    width: 80%;
    background-color: var(--blue) !important;

    &:disabled {
        background-color: var(--blue) !important;
        opacity: 0.3;
        color: var(--white) !important;
        cursor: not-allowed !important;
    }
}

.nextIcon {
    width: 20%;
    background-color: var(--light-blue) !important;

    &:disabled {
        background-color: var(--light-blue) !important;
        opacity: 0.3;
        color: var(--white) !important;
        cursor: not-allowed !important;
    }
}

.block {
    float: left;
    width: 100%;
}

.field {
    font-size: 14px !important;
    color: var(--black) !important;
    padding: 0 5px 0 15px !important;
    background-color: var(--white) !important;
    box-shadow: 0px 4px 16px #455B6314 !important;
    border-radius: var(--m-spacing) !important;
    margin-top: 15px !important;
    width: -webkit-fill-available !important;
    box-sizing: border-box !important;
    &:focus {
        border: 1px solid #0047AB !important;
    }  
}

.label {
    color: var(--black) !important;
    font-size: 18px !important;
    font-weight: bold;
    padding-left: 15px;
    top: -10px !important;
    transform-origin: unset !important;
    transform: initial !important;
}

.textarea {
    color: #334856 !important;
    padding: 15px 5px 5px 15px !important;
    background-color: #ffffff !important;
    box-shadow: 0px 4px 16px #455B6314 !important;
    border-radius: 8px !important;
    margin-top: 15px !important;
    width: -webkit-fill-available !important;
    width: -moz-available !important;
    height: auto !important;
    resize: none !important;

    &:focus {
        border: 1px solid #0047AB !important;
    }
}

.inputDivWithBorder {
    margin-bottom: 0;
    border-bottom: 1px solid #D8D8D8;
}

.flexSpacing {
    justify-content: space-between;
}

.info {
    display: none;
    width: 100%;
    background-color: var(--blue);
    color: var(--white);
    text-align: left;
    border-radius: 6px;
    padding: 0 10px;
    position: absolute;
    z-index: 1;
    font-weight: 300;
    top: 30px;

    &:after {
        content: "";
        position: absolute;
        left: 180px;
        top: -16px;
        border-width: 8px;
        border-style: solid;
        border-color: transparent transparent #0047AB transparent;
    }
}

.show {
    display: block !important;
}

.infoIcon {
    color: var(--blue);
    position: relative;
    top: 3px;
    margin-left: 5px;
    cursor: pointer;
}

.tooltip {
    cursor: pointer;
}

.additems {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: 6%;
}

.link {
    color: var(--black);
}

.addItemsBtn {
    background-color: var(--white);
    box-shadow: 0px 20px 50px #0047AB;
    color: var(--black);
    font-weight: var(--semi-bold);
    text-transform: capitalize;
    border-radius: 25px;
    padding: 0px 50px;
    background-color: var(--white) !important;

    &:hover {
        box-shadow: 0px 20px 50px var(--blue);
        background-color: var(--white) !important;
    }
}

.noItem {
    margin-top: var(--xxxxxl-spacing);
}

.itemsHeaderSection {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.itemsHeader {
    font-size: 18px;
    color: var(--black);
    font-weight: var(--bold);
}


.items {
    font-size: var(--h3-font-size);
    font-weight: var(--semi-bold);
    color: var(--blue);
    padding-top: var(--xs-spacing);
}

.addedItem {
    display: flex;
    align-items: center;
    margin: 8px 0px !important;
    font-family: 'Raleway', sans-serif;
}

.accordion {
    background-color: #f0f3f4 !important;
     box-shadow: none !important;
     margin: -10px 0px !important; 
     width: 100% !important;
}

.accordionSummary{
    margin-left: -18px !important;
}

.delIcon {
    color: #FF7777;
    background-color: #ffffff;
    font-size: 28px;
    padding: 10px;
    cursor: pointer;
    border-radius: 8px;
    margin-right: 3px;
}

.f24 {
    font-size: var(--h1-font-size) !important;
    font-weight: var(regular);  
}

.f18 {
    font-size: 18px !important;
    margin: 0 var(--m-spacing) !important;
    word-break: break-word;
}

.iosBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
    border-top: 1px solid #D8D8D8;
}

.iosBlocks {
    display: flex;
    width: 140%;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
    border-top: 1px solid #D8D8D8;
}

.iosBlockDetails {
    margin: -25px 0px;
}

.iosBlockCircle {
    padding: 10px;
    margin: 10px;
    border-radius: 50px;
}

.iosSlider {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px !important;
}

.width80{
    width: 50%;
    text-align: end;
}

.done {
    margin: 2px 0px;
}

.doneMessage {
    color: #ABABAB;
    float: left;
    width: 100%;
    padding: 0 0px;
}

.copySection {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.shareIcon {
    position: absolute;
    left: 10px;
    top: 11px;
    transform: rotate(90deg);
    color: #8B8C94;
}

.shareInput {
    margin-top: 0px !important;
    font-size: 18px !important;
    font-family: 'Raleway', sans-serif;
    padding-left: 38px !important;
    margin-right: 10px !important;
    background-color: var(--white) !important;
    border: none !important;
    border-radius: 6px !important;
    color: #334856 !important;
}

.cpyBtn {
    background-color: #CE4A80 !important;
    border-radius: 8px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -8px;
    transition: .3s ease-out;

    &:hover {
        background-color: #CE4A80 !important;
    }
}

.signupBtn {
  background-color: var(--white);
  box-shadow: 0px 20px 50px #0047ab;
  color: var(--pink);
  font-weight: 700;
  text-transform: capitalize;
  border-radius: 25px;
  padding: 0px 25px;
  background-color: var(--white) !important;

  &:hover {
    box-shadow: 0px 20px 50px var(--blue);
    background-color: var(--white) !important;
  }
}

.applyBaselining {
    align-items: baseline;
}

.errorText {
    font-size: 0.9rem;
    color: red;
}

.editButtonIcon {
    color: var(--blue) !important;
}

 /* User Story 40085: Paid feature popup it should say Paid feature ! upgrade now */
.name {
    font-size: 20px;
    position: relative;
    width: 80%;

    &::after {
        content: '';
        position: absolute;
        left: 5px;
        bottom: -1px;
        height: 1px;
        width: 90%;
        background-color: var(--underlineColor, #ffffff);
    }
}