.smash-participants-lists-div {
    margin-top: 20px;
}
.participants-list-block {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 0px 4px #00000029;
    margin-top: 10px;
    padding: 10px 15px;
    font-weight: 500;
    font-family: 'Raleway',sans-serif;
}
.participant-list-img i {
    background-color: #D8D8D8;
    color: #ACACAC;
    font-size: 21px;
    width: 38px;
    height: 38px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.participant-list-img-participant {
    display: inline-block;
}
.participant-list-img-participant i {
    width: 98px;
    height: 98px;
}
.participant-list-det {
    word-break: break-all;
    text-align: left;
    margin-left: 10px;
}
.participant-list-det h5 {
    font-size: 13px;
    color: #ACACAC;
    margin: 12px 5px 10px 0px;
    font-weight: 500;
    font-family: 'Raleway',sans-serif;
}
.participant-list-det p {
    margin-top: 10px;
    font-size: 12px;
    color: #ACACAC;
}
.participant-session {
    margin: auto;
    margin-right: 10px;
    font-size: 14px;
    color: black;
    font-weight: 500;
}
.participant-session > span {
    color: #ffb84d;
    word-break: break-word;
    font-size: 18px;
    padding-right: 10px;
}
.participant-list-detail h4 {
    font-size: 24px;
    font-weight: bold;
    word-break: break-all;
    color: #454F63;
    margin: 20px 0 4px;
    font-family: 'Raleway',sans-serif;
}
.participant-list-detail p {
    font-size: 16px;
    color: #78849E;
    margin: 0;
}
.smash-lists-block.preview-result-detail-block>h5 {
    color: #ffffff;
}
.smash-lists-block {
    text-align: left;
}
.smash-lists-block>h5 {
    font-size: 18px;
    font-weight: bold;
    color: #334856;
    margin: 0;
}
.smash-lists-block.smash-detail-block {
    display: flex;
    justify-content: space-between;
}
.smash-lists-block.smash-detail-block {
    display: flex;
    justify-content: space-between;
}
.smash-body {
    padding: 30px 20px 70px 20px;
    float: left;
    width: 100%;
}
.smash-body.smash-body-participant {
    background-color: #ffffff;
}
span.header-breadcrumb a {
    color: #212430;
}
span.header-breadcrumb {
    font-size: 16px;
    font-weight: 600;
    font-family: 'Raleway', sans-serif;
    cursor: pointer;
}
span.header-icon {
    font-weight: bold;
    font-size: 18px;
    display: flex;
    align-items: center;
    text-align: left;
}
span.header-icon i {
    font-size: xx-large;
    padding-right: 25px;
    position: relative;
    top: -1px;
    color: #324755;
}
h6.smash-headerh6 {
    color: #324755;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.smash-header {
    height: 100px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dfdfdf;
    padding: 0 20px;
}
.form-smash-block input {
    font-family: 'Nunito', sans-serif;
    font-size: 14px !important;
    color: #334856 !important;
    padding: 0 5px 0 15px !important;
    background-color: #ffffff !important;
    border: 1px solid transparent !important;
    box-shadow: 0px 4px 16px #455B6314 !important;
    border-radius: 8px !important;
    margin-top: 30px !important;
    width: -webkit-fill-available !important;
    width: -moz-available !important;
}
.form-smash-block textarea {
    font-family: 'Nunito', sans-serif;
    font-size: 14px !important;
    color: #334856 !important;
    padding: 15px 5px 5px 15px !important;
    background-color: #ffffff !important;
    border: 1px solid transparent !important;
    box-shadow: 0px 4px 16px #455B6314 !important;
    border-radius: 8px !important;
    margin-top: 30px !important;
    width: -webkit-fill-available !important;
    width: -moz-available !important;
    height: auto;
    resize: none;
}
.form-smash-block input:focus, .form-smash-block textarea:focus {
    border: 1px solid #0047AB !important;
}
.form-smash-block label {
    color: #334856 !important;
    font-size: 18px !important;
    font-weight: bold;
    padding-left: 15px;
    top: -10px;
    transform-origin: unset !important;
    transform: initial !important;
}
.form-smash-block {
    float: left;
    width: 100%;
}
.form-smash-block span.lever {
    background-color: #ACACAC;
    margin: 0 !important;
    height: 30px !important;
    width: 55px !important;
}
.form-smash-block .switch label .lever:before, .form-smash-block .switch label .lever:after {
    width: 28px;
    height: 28px;
    left: 1px;
    top: 1px;
}
.form-smash-block .switch label input[type=checkbox]:checked+.lever:after, .form-smash-block .switch label .lever:after {
    background-color: #ffffff;
}
.form-smash-block .switch label input[type=checkbox]:checked+.lever:before, .form-smash-block .switch label input[type=checkbox]:checked+.lever:after {
    left: 26px;
}
.form-smash-block .switch label input[type=checkbox]:checked+.lever {
    background-color: #CE4A80;
}
.form-smash-block.timer-text-switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 15px;
}
.form-smash-block.timer-text-switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 15px;
    border-top: 1px solid #D8D8D8;
    margin: 10px 0px 5px;
    padding: 15px 0px 0px;
}
.generated-link-block.form-smash-block input {
    margin-top: 0px !important;
    font-size: 18px !important;
    font-family: 'Raleway', sans-serif;
    padding-left: 38px !important;

}
.generated-link-block.form-smash-block {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
}
.generated-link-block.form-smash-block ::-webkit-input-placeholder {
    color: #3b3c49;
}
.generated-link-block.form-smash-block :-ms-input-placeholder {
    color: #8B8C94;
}
.generated-link-block.form-smash-block ::placeholder {
    color: #8B8C94;
}
.create-smash-block {
    background-color: #ffffff;
    width: 100%;
    box-shadow: 0px -3px 6px #00000029;
}
.smash-block {
    float: left;
    width: 100%;
}
.form-smash-block.preview-thankyou-block .input-field {
    margin: 0;
}
.wrapper-body.wrapper-preview-section {
    background: linear-gradient(to bottom, #0047AB, #CE4A80);
}
.wrapper-body {
    width: 400px;
    height: 100vh;
    overflow: hidden;
    text-align: center;
    border: 1px solid #dfdfdf;
    background-color: #F0F3F4;
    position: relative;
}
.wrapper-body.wrapper-flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
}
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FAFAFA;
    /* height: 100vh; */
}
.wrapper-body {
    width: 400px;
    height: 100vh;
    overflow: hidden;
    text-align: center;
    border: 1px solid #dfdfdf;
    background-color: #F0F3F4;
    position: relative;
}
.wrapper-body.wrapper-flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
}
.wrapper-body.wrapper-preview-section {
    background: linear-gradient(to bottom, #0047AB, #CE4A80);
}

.backBtn {
    background-color: transparent !important;
    border: none;
}

.backIcon {
    font-size: 32px;
    padding-right: var(--xxl-spacing);
    position: relative;
    top: -1px;
    color: var(--black);
    cursor: pointer;
}
