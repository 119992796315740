/* .text { 
    position: absolute;
    left: calc(100% - 55%);
    bottom: 0.5%;
    font-size: 1.2rem;
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    color: #ffffff;
} */
.text { 
    position: absolute;
    width: 100%;
    bottom: 0%;
    font-size: 1.2rem;
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    color: #ffffff;
}