.lever {
    background-color: #ACACAC;
    margin: 0 !important;
    height: 30px !important;
    width: 55px !important;
    content: "";
    display: inline-block;
    position: relative;
    border-radius: 15px;
    -webkit-transition: background 0.3s ease;
    transition: background 0.3s ease;
    vertical-align: middle;
    &::before{
        width: 28px !important;
        height: 28px !important;
        left: 1px !important;
        top: 1px !important;
        content: "" !important;
        position: absolute !important;
        display: inline-block !important;
        border-radius: 50% !important;
    }

    &::after {
        width: 28px !important;
        height: 28px !important;
        left: 1px !important;
        top: 1px !important;
        content: "" !important;
        position: absolute !important;
        display: inline-block !important;
        border-radius: 50% !important;
        background-color: #ffffff !important;
        box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    }
}

.input {
    font-family: 'Nunito', sans-serif;
    font-size: 14px !important;
    color: #334856 !important;
    padding: 0 5px 0 15px !important;
    background-color: #ffffff !important;
    border: 1px solid transparent !important;
    box-shadow: 0px 4px 16px #455B6314 !important;
    border-radius: 8px !important;
    margin-top: 30px !important;
    width: -webkit-fill-available !important;
    position: absolute;
    opacity: 0;
    height: 0;
}
