.info {
  display: none;
  width: 350px;
  background-color: var(--blue);
  color: var(--white);
  text-align: left;
  border-radius: 6px;
  padding: 0 10px;
  position: absolute;
  z-index: 1000;
  font-weight: 300;
  top: 39px;
}

.show {
  display: block !important;
}

.infoIcon {
  color: var(--blue);
  position: relative;
  top: 3px;
  margin-left: 5px;
  cursor: pointer;
}

.tooltip {
  cursor: pointer;

  &:focus {
    outline: none !important;
    border: none !important;
  }
}

.labelAndTooltipContainer {
  display: flex;
  align-items: center;
}

.label {
  color: var(--black) !important;
  font-size: 18px !important;
  font-weight: bold;
  padding-left: 15px;
  top: -10px !important;
  transform-origin: unset !important;
  transform: initial !important;
}

.text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.pointer {
  position: absolute;
  top: 20px;
  left: 4px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent #0047ab transparent;
}
