.block {
  float: left;
  width: 100%;
  margin-bottom: 0px;
  padding: 0 0 20px 0;
}

.header {
  height: 100px;
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--grey);
  padding: 0 var(--xl-spacing);
}

.h6 {
  color: var(--black);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon {
  font-weight: bold;
  font-size: 18px;
  display: flex;
  align-items: center;
  text-align: left;
}

.backBtn {
  background-color: transparent !important;
  border: none;
}

.backIcon {
  font-size: 32px;
  padding-right: var(--xxl-spacing);
  position: relative;
  top: -1px;
  color: var(--black);
  cursor: pointer;
}

.footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
  z-index: 1030;
}

.buttonGroup {
  display: inline-flex;
  width: 100%;
}

.next {
  width: 100%;
  background-color: var(--blue) !important;

  &:disabled {
    background-color: var(--blue) !important;
    opacity: 0.3;
    color: var(--white) !important;
    cursor: not-allowed !important;
  }
}

.field {
  font-size: 14px !important;
  color: var(--black) !important;
  padding: 0 5px 0 15px !important;
  background-color: var(--white) !important;
  box-shadow: 0px 4px 16px #455b6314 !important;
  border-radius: var(--m-spacing) !important;
  margin-top: 15px !important;
  width: -webkit-fill-available !important;
  box-sizing: border-box !important;

  &:focus {
    border: 1px solid #0047ab !important;
  }
}

.label {
  color: var(--black) !important;
  font-size: 18px !important;
  font-weight: bold;
  padding-left: 15px;
  top: -10px !important;
  transform-origin: unset !important;
  transform: initial !important;
}

.textarea {
  color: #334856 !important;
  padding: 15px 5px 5px 15px !important;
  background-color: #ffffff !important;
  box-shadow: 0px 4px 16px #455b6314 !important;
  border-radius: 8px !important;
  margin-top: 15px !important;
  width: -webkit-fill-available !important;
  width: -moz-available !important;
  height: auto !important;
  resize: none !important;

  &:focus {
    border: 1px solid #0047ab !important;
  }
}

.body {
  padding: 30px 20px 70px 20px;
  float: left;
  width: 100%;
  height: calc(100vh - 230px);
  overflow-y: auto;
  overflow-x: hidden;
}

.addIcon {
  font-size: 47px;
}

.iconSection {
  text-align: left;
  margin-top: 15px;
  padding-left: 5px;
  cursor: pointer;
}

.hide {
  display: none;
}

.added {
  text-align: left;
  float: left;
  background: var(--white);
  border-radius: 6px;
  padding: 8px;
  margin-top: 10px;
}
