.main {
    display: flex;
    align-items: center;
    margin-top: auto;
    flex-direction: column;
}

.previewSection {
    background: linear-gradient(to bottom, var(--blue), var(--pink));
    height: calc(100vh - 50px);
    overflow-y: auto;
}

.totalWidth {
    height: 100vh;
    overflow-y: auto;
    background: linear-gradient(to bottom, var(--blue), var(--pink));
}

.footer{
    position: absolute;
    bottom: 0px;
    width: 100%;
    z-index: 1030;
}

.buttonGroup {
    display: inline-flex;
    width: 100%;
    height: 50px;
}

.next {
    width: 80%;
    background-color: var(--blue) !important;

    &:disabled {
        background-color: var(--blue) !important;
        opacity: 0.3;
        color: var(--white) !important;
        cursor: not-allowed !important;
    }
}

.nextIcon {
    width: 20%;
    background-color: var(--light-blue) !important;

    &:disabled {
        background-color: var(--light-blue) !important;
        opacity: 0.3;
        color: var(--white) !important;
        cursor: not-allowed !important;
    }
}


.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: var(--white);
}

.closeButton {
    width: 33px;
    height: 33px;
    background-color: var(--white) !important;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none !important;
    cursor: pointer;
}

.closeBlock {
    margin-bottom: -10px;
    margin-right: 15px;
}

.previewBody {
    /* padding: 10px 0px 60px 0px; */ /*raunak */
    /* padding: 10px 0px 0px 0px; */ /*raunak */
    float: left;
    width: 100%;
    /* height: 89vh; */ /*raunak */
    height: 100%;
}

.occupyFullHeight {
    height: 100vh;
}

.logoBlock{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
}

.logoShape {
    background: #ffffff;
    height: 64px;
    width: 64px;
    text-align: center;
    transform: rotate(45deg);
}

.logoUrl {
	margin-top: 30px;
    width: 300px;
    object-fit: cover;
}

.poweredByLogoUrl {
	margin-top: 12px;
    height: 40px;
    object-fit: cover;
}

.poweredBy {
    display: block;
    color: white;
}

.headline {
    font-size: 38px;
    font-weight: bold;
    color: #ffffff;
    font-family: 'Raleway', sans-serif;
    margin: 10px 0 0;
}

.closeIconSection {
    text-align: right;
    margin: 0;
}

.closeicon {
    font-size: 20px;
    color: var(--black);
    line-height: 1.5;
    font-weight: bold;
}

.logoR {
    font-size: 38px;
    color: #6504ED;
    font-weight: bold;
    font-family: 'Raleway', sans-serif;
    display: table-cell;
    height: 64px;
    width: 64px;
    transform: rotate(-45deg);
    vertical-align: middle;
}

.surveyBlock {
    text-align: left;
    color: #ffffff;
    /* position: absolute; */
    bottom: 20%;
    margin: 0 20px;
    word-break: break-word;
}

.name {
    font-size: 30px;
    font-weight: bold;
    position: relative;
    margin: 20px 0;
    margin-bottom: 24px;

    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -10px;
        height: 1px;
        width: 15%;
        background-color: var(--underlineColor, #ffffff);
    }
}

.desc {
    font-size: 18px;
    font-weight: 300
}

.thankyouBlock {
    color: #ffffff;
    padding: 0 35px;
}

.tqText {
    font-size: 24px;
    font-weight: bold;
    line-height: 35px;
    position: relative;

    &::after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: -8px;
        transform: translate(-50%, -50%);
        height: 1px;
        width: 10%;
        background-color: var(--underlineColor, #ffffff);
    }
}

.emailText {
    font-size: 18px;
    font-weight: 100;
    margin-top: 20px;
    line-height: 25px;
}

.block {
    float: left;
    width: 100%;
}

.field {
    font-size: 14px !important;
    color: var(--black) !important;
    padding: 0 5px 0 15px !important;
    background-color: var(--white) !important;
    box-shadow: 0px 4px 16px #455B6314 !important;
    border-radius: var(--xs-spacing) !important;
    margin-top: 30px !important;
    width: -webkit-fill-available !important;

    &:focus {
        border: 1px solid #0047AB !important;
    }
}
