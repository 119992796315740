.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  /* height: 100vh; */
}
.wrapper-body {
  width: 400px;
  height: 100vh;
  overflow: hidden;
  text-align: center;
  border: 1px solid #dfdfdf;
  background-color: #f0f3f4;
  position: relative;
}
.form-smash-block input {
  font-family: "Nunito", sans-serif;
  font-size: 14px !important;
  color: #334856 !important;
  padding: 0 5px 0 15px !important;
  background-color: #ffffff !important;
  border: 1px solid transparent !important;
  box-shadow: 0px 4px 16px #455b6314 !important;
  border-radius: 8px !important;
  margin-top: 30px !important;
  width: -webkit-fill-available !important;
  width: -moz-available !important;
}
.form-smash-block textarea {
  font-family: "Nunito", sans-serif;
  font-size: 14px !important;
  color: #334856 !important;
  padding: 15px 5px 5px 15px !important;
  background-color: #ffffff !important;
  border: 1px solid transparent !important;
  box-shadow: 0px 4px 16px #455b6314 !important;
  border-radius: 8px !important;
  margin-top: 30px !important;
  width: -webkit-fill-available !important;
  width: -moz-available !important;
  height: auto;
  resize: none;
}
.form-smash-block input:focus,
.form-smash-block textarea:focus {
  border: 1px solid #0047ab !important;
}
.form-smash-block label {
  color: #334856 !important;
  font-size: 18px !important;
  font-weight: bold;
  padding-left: 15px;
  top: -10px;
  transform-origin: unset !important;
  transform: initial !important;
}
.form-smash-block {
  float: left;
  width: 100%;
}
.form-smash-block span.lever {
  background-color: #acacac;
  margin: 0 !important;
  height: 30px !important;
  width: 55px !important;
}
.form-smash-block .switch label .lever:before,
.form-smash-block .switch label .lever:after {
  width: 28px;
  height: 28px;
  left: 1px;
  top: 1px;
}
.form-smash-block .switch label input[type="checkbox"]:checked + .lever:after,
.form-smash-block .switch label .lever:after {
  background-color: #ffffff;
}
.form-smash-block .switch label input[type="checkbox"]:checked + .lever:before,
.form-smash-block .switch label input[type="checkbox"]:checked + .lever:after {
  left: 26px;
}
.form-smash-block .switch label input[type="checkbox"]:checked + .lever {
  background-color: #ce4a80;
}
.form-smash-block.timer-text-switch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px;
}
.form-smash-block.timer-text-switch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px;
  border-top: 1px solid #d8d8d8;
  margin: 10px 0px 5px;
  padding: 15px 0px 0px;
}
.generated-link-block.form-smash-block input {
  margin-top: 0px !important;
  font-size: 18px !important;
  font-family: "Raleway", sans-serif;
  padding-left: 38px !important;
}
.generated-link-block.form-smash-block {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
}
.generated-link-block.form-smash-block ::-webkit-input-placeholder {
  color: #3b3c49;
}
.generated-link-block.form-smash-block :-ms-input-placeholder {
  color: #8b8c94;
}
.generated-link-block.form-smash-block ::placeholder {
  color: #8b8c94;
}
.create-smash-block {
  background-color: #ffffff;
  width: 100%;
  box-shadow: 0px -3px 6px #00000029;
}
.smash-block {
  float: left;
  width: 100%;
}
.form-smash-block.preview-thankyou-block .input-field {
  margin: 0;
}
h6.smash-headerh6 {
  color: #324755;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
span.header-icon {
  font-weight: bold;
  font-size: 18px;
  display: flex;
  align-items: center;
  text-align: left;
}
span.header-icon i {
  font-size: xx-large;
  padding-right: 25px;
  position: relative;
  top: -1px;
  color: #324755;
}
i.material-icons.generated-link-linkicon {
  position: absolute;
  left: 10px;
  top: 11px;
  transform: rotate(90deg);
  color: #8b8c94;
}
span.header-breadcrumb {
  font-size: 16px;
  font-weight: 600;
  font-family: "Raleway", sans-serif;
  cursor: pointer;
}
span.header-breadcrumb a {
  color: #212430;
}
.smash-body {
  padding: 30px 20px 70px 20px;
  float: left;
  width: 100%;
  height: calc(100vh - 154px);
  overflow: auto;
  position: relative;
}
.smash-body.smash-body-participant {
  background-color: #ffffff;
}
.smash-lists-block {
  text-align: left;
}
.smash-lists-block > h5 {
  font-size: 18px;
  font-weight: bold;
  color: #334856;
  margin: 0;
}
.smash-lists-block.smash-detail-block {
  display: flex;
  justify-content: space-between;
}
.smash-lists-block.preview-result-detail-block > h5 {
  color: #ffffff;
}

.global-setting-div p {
  color: #334856;
  margin-bottom: 0;
  font-weight: 650;
}

.removeMargins {
  margin: 0;
}

.smash-list-div {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 15px 0px 15px 15px;
  margin-top: 12px;
  font-family: "Raleway", sans-serif;
}
.smash-list-div h5 {
  font-size: 18px;
  margin: 0 0 5px;
  font-weight: 500;
  font-family: "Raleway", sans-serif;
}
.smash-list-div p {
  margin: 0;
}
.smash-list-div > a {
  color: #212430;
}
.button-smash-share {
  float: right;
  margin-top: -23px;
  margin-bottom: -11px;
  margin-right: 1px;
}
.button-smash-share a,
.button-smash-share button {
  background-color: #ce4a80;
  border-radius: 8px 0px 8px 0px;
  box-shadow: none;
  text-transform: capitalize;
}
.button-smash-share a:hover,
.button-smash-share button:hover {
  background-color: #ce4a80;
  box-shadow: none;
}
.button-smash-share.cannot-share a,
.button-smash-share.cannot-share button {
  background-color: #d8d8d8;
}
.button-smash-share a > i,
.button-smash-share button > i {
  font-size: 12px;
}
.button-smash-share.edit-smash a,
.button-smash-share.edit-smash button {
  width: 87.78px;
  background-color: #0047ab;
}
.smash-footer {
  position: fixed;
  bottom: 0px;
  width: 400px;
  z-index: 1030;
}
a.waves-effect.btn-large.create-smash-btn,
button.waves-effect.btn-large.create-smash-btn {
  background-color: #ffffff;
  border-radius: 25px;
  box-shadow: 0px 20px 50px #0047ab;
  position: relative;
  top: -30px;
  color: #1b1c20;
  font-size: 14px;
  font-weight: 600;
  padding: 0 30px 0 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 300px) {
    right: 15%;
  }
}
a.waves-effect.btn-large.create-smash-btn > i,
button.waves-effect.btn-large.create-smash-btn > i {
  background-color: #ce4a80;
  width: 34px;
  height: 34px;
  border-radius: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  margin-right: 15px;
}
.toast .toast-action {
  color: #0047ab;
  background-color: #ffffff;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  position: relative;
}
.toast .toast-action::before {
  content: "";
  position: absolute;
  left: -20px;
  width: 1px;
  height: 130%;
  background-color: #ffffff;
}

.toast-close {
  color: black;
  background-color: #ffffff !important;
  border-radius: 100%;
  width: 25px;
  height: 25px;
  padding: 0;
  margin: 0;
  line-height: 1.5;
  font-weight: bolder;
}

.flex {
  display: flex;
  align-items: center;
}

.width80 {
  width: 90%;
}
.smash-settings-div {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 5px 0px 15px 15px;
  margin-top: 2px;
  font-family: "Raleway", sans-serif;
}

.field {
  font-size: 14px !important;
  color: var(--black) !important;
  padding: 0 5px 0 15px !important;
  background-color: var(--white) !important;
  box-shadow: 0px 4px 16px #455b6314 !important;
  border-radius: var(--m-spacing) !important;
  margin-top: 15px !important;
  width: -webkit-fill-available !important;
  box-sizing: border-box !important;
}

.field:focus {
  border: 1px solid #0047ab !important;
}

.trackingprovidergroup > .MuiFormGroup-root {
  flex-direction: row;
}

.closeicon {
  font-size: 20px;
  color: var(--black);
  line-height: 1.5;
  font-weight: bold;
}

.closeButton {
  width: 33px;
  height: 33px;
  max-width: 33px;
  max-height: 33px;
  min-width: 33px;
  min-height: 33px;
  border-radius: 50%;
  background-color: var(--white) !important;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none !important;
  cursor: pointer;
  padding: 0;
  @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
    width: 11%;
    height: 5%;
  }
}

.backdroploader {
  background: rgba(221, 221, 221, 0.5);
  position: absolute;
  display: block;
  z-index: 100;
  left: 0;
  top: 0px;
  height: 100%;
  width: 100%;
}

.button-edit-share {
  float: right;
  margin-top: -22px;
  margin-right: 20px;
}
.editButton {
  color: #000000;
  font-size: 30px;
}

.sub-header {
  font-weight: bold;
}

.text-left {
  padding-left: 15px;
  display: inline-block;
}

.add-logo-link {
  display: inline-block;
  font-weight: bold;
  margin-left: 5px;
  cursor: pointer;
}

.logo-tooltip {
  display: inline-block;
}

.logo-tooltip > div > div {
  left: -100px;
}

.span-position {
  width: 41%;
}

.add-logo {
  text-align: left;
  float: left;
  background: var(--white);
  border-radius: 6px;
  padding: 8px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.logoUrl {
  height: 70px;
  object-fit: cover;
  margin: 20px;
}

.uploaded-logo {
  background-color: white;
  border-radius: 8px;
  display: flex;
}

.uploaded-logo-image {
  width: 70%;
}

.uploaded-logo-delete {
  margin: auto;
}

.delete-icon {
  cursor: pointer;
}

.customize-smash-theme-div p {
  margin: 0;
  color: #039be5;
}

/* .nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: left;
  width: 70vw;
  justify-content: end;
  margin-right: 2rem;
} */

.nav-item {
  display: flex;
  align-items: left;
  height: 80px;
}

.nav-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 154px);
  position: absolute;
  top: 80px;
  left: -100%;
  opacity: 1;
  transition: all 0.5s ease;
  z-index: 100;
  padding-top: 20px;
}

.nav-menu.active {
  background: #e7e7e7;
  left: 0;
  opacity: 1;
  transition: all 0.5s ease;
  z-index: 20;
}

.nav-menu li {
  height: 45px;
  padding-left: 25px;
  display: flex;
  align-items: center;
}

.nav-menu > li > span {
  font-size: 18px;
  cursor: pointer;
}
