.list {
  margin-top: 20px;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 4px #00000029;
  margin-top: 5px;
  padding: 0 15px;
  font-weight: 500;
  font-family: "Raleway", sans-serif;
}

.name {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  color: #212430;
  word-break: break-word;
}

.icon {
  color: #d8d8d8;
  font-size: 36px;
  width: 36px;
  height: 36px;
  margin-right: 10px;
}

.maticon {
  color: #212430;
  margin-right: 10px;
  font-size: 30px !important;
}

.iconAndRank {
  display: flex;
  align-items: center;
}

.rank {
  color: #ffb84d;
  font-size: 20px;
}

.label {
  font-size: 18px;
  font-weight: bold;
  color: var(--white);
  margin: 0;
  text-align: left;
  margin-top: 20px;
}
