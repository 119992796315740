@custom-media --phone (max-width: 576px);

@custom-media --desktop (min-width: 1025px);

:root {
  --xxs-spacing: 2px;
  --xs-spacing: 4px;
  --s-spacing: 8px;
  --m-spacing: 12px;
  --l-spacing: 16px;
  --xl-spacing: 20px;
  --xxl-spacing: 24px;
  --xxxl-spacing: 28px;
  --xxxxl-spacing: 32px;
  --xxxxxl-spacing: 36px;
  --xxxxxxl-spacing: 40px;

  --bold: 700;
  --semi-bold: 600;
  --regular: 400;
  
  --black: #212430;
  --red: red;
  --white: #ffffff;
  --grey: #dfdfdf;
  --blue: #0047AB;
  --pink: #CE4AB0;
  --light-blue: #2F83F9;
  --light-pink: #CE4A80;

  --border-radius: 8px;

  --h1-font-size: 24px;
  --h2-font-size: 20px;
  --h3-font-size: 16px;
  --l-font-size: 14px;
  --m-font-size: 12px;
  --s-font-size: 10px;
  --xs-font-size: 8px;

  --body-line-height: 1.5;
}

h1,h2,h3,h4,h5,h6{
  font-family: 'Nunito', sans-serif;
}

body {
  margin: 0;
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  color: #212430;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.error {
  border: 1px solid var(--red) !important;
}
