.body {
    padding-top: 0;
}

.lists {
    display: flex;
    flex-direction: column;
    padding-bottom: 3%;
    margin-top: 0;
    border-bottom: 1px solid #D8D8D8;
}

.inputContainer {
    display: flex;
}

.inputContainer div:first-child {
    width: 70%;
}

.label {
    font-size: 1.2rem !important;
}

.inputs {
    width: 70% !important;
    margin-left: 10% !important;
}

.errorText {
    font-size: 0.9rem;
    color: red;
}

.buttonText {
    border-bottom: 0;
}