.thankyouBlock {
  color: #ffffff;
}

.resultAndComparisonText {
  &::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -8px;
    transform: translate(-50%, -50%);
    height: 1px;
    width: 10%;
    background-color: var(--underlineColor, #ffffff);
  }
}

.tqText {
  font-size: 24px;
  font-weight: bold;
  line-height: 35px;
  position: relative;
  padding: 0 1.5%;
}

.emailText {
  font-size: 18px;
  font-weight: 100;
  margin-top: 20px;
  line-height: 25px;
}

.link {
  font-size: 15px;
  color: white !important;
}

.footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
  z-index: 1030;
}

.tryYourself {
  font-size: 24px;
  font-weight: 100;
  margin-top: 20px;
  line-height: 25px;
  color: white;
}

.buttonGroup {
  width: 100%;
  box-sizing: border-box;
  @media screen and (max-width: 280px) {
    width: 100vw;
  }
}

.next {
  width: 80%;
  background-color: var(--blue) !important;
}

.nextIcon {
  width: 20%;
  background-color: var(--light-blue) !important;
}

.block {
  margin: 0 20px;
  padding-bottom: 120px;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: var(--white);
}

@media only screen and (max-width: 420px) {
  .block {
    padding-bottom: 180px;
  }
}
