.thankyouBlock {
  color: #ffffff;
  overflow: hidden;
  /* height: inherit;  */ /*raunak*/
  /* height: 95%; */ /*raunak*/
  height: calc(100% - 30px);
  margin-bottom: 20px;
}

.smashDesc {
  /* display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 2% 0;
  min-height: fit-content;
  max-height: 26vh;
  max-height: 25%; */
}

.tqText {
  font-size: 24px;
  font-weight: bold;
  line-height: 35px;
  position: relative;
  margin: 5px 20px;
  word-break: break-word;

  &::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -8px;
    transform: translate(-50%, -50%);
    height: 1px;
    width: 10%;
    background-color: var(--underlineColor, #ffffff);
  }
}

.tqTextSmall {
  /* font-size: 18px; */
  font-size: 1.2rem; /*raunak*/
  line-height: 25px;
  position: relative;
  margin: 5px 20px;
  word-break: break-word;

  &::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -8px;
    transform: translate(-50%, -50%);
    height: 1px;
    width: 10%;
    background-color: var(--underlineColor, #ffffff);
  }
}
.tqTextMedium {
  font-size: 20px;
  font-size: 1.4rem;
  line-height: 25px;
  position: relative;
  margin: 5px 20px;
  word-break: break-word;

  &::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -8px;
    transform: translate(-50%, -50%);
    height: 1px;
    width: 10%;
    background-color: var(--underlineColor, #ffffff);
  }
}

.tqTextLarge {
  /* font-size: 22px; */
  font-size: 1.7rem; /* raunak*/
  line-height: 25px;
  position: relative;
  margin: 5px 20px;
  word-break: break-word;

  &::after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: -8px;
    transform: translate(-50%, -50%);
    height: 1px;
    width: 10%;
    background-color: var(--underlineColor, #ffffff);
  }
}

.emailText {
  /* font-size: 18px; */
  font-size: 1.3rem; 
  /* font-size: 2.5rem; */ /*raunak*/
  font-weight: 100;
  margin-top: 20px;
  line-height: 25px;
}

.selectionBox {
  /* display: flex;
  transition: 0.2s;
    height: min(80%, 74vh);
  max-height: fit-content; */

  /* margin-top: 10px;
    height: calc(100vh - 220px);
  height: 100%; 
  height: inherit;
  max-height: 92%;
  height: 80%;  */
  
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  /* justify-content: space-between; */
  justify-content: flex-end;
  background-color: #ffffff;
  color: #212430;
  border-radius: 8px;
  text-align: left;
  cursor: pointer;
  /* height: calc(100vh - 72vh); */
  /* height: 26vh; */
  height: 44%;
  padding: 4px;
}
/* User Story : Add a button to the card */
.imgAndPContainer {
  display: flex !important;
  align-items: center;
  /* background: aqua; */
  /* height: 19vh; */
  /* height: fit-content;  */ /* raunak */
  min-height: 60%;
  max-height: 80%;
  width: 100%;
  height: min(23vh, 80%);
}

/* User Story : Add a button to the card */
.buttonGroupWithinCards { /* raunak */
  display: inline-flex;
  width: 100%;
  height: auto;
}

/* User Story 31089: Maintain aspect ratio of Square images */
.imgBlock {
  /* width: 100%;
  height: 100% !important;
  object-fit: cover;
  overflow: hidden;
  position: relative;
  display: flex;
  height: 173px; Note: Height is commented*/

  display: flex;
  object-fit: cover;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
}

.textBlock {
  width: 100%;
  /* height: 173px; */
  height: 100%;
  overflow: hidden;
  padding: 0px 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  word-break: break-word;
}

/* User Story 31089: Maintain aspect ratio of Square images */
.img {
  /* max-width: 100%;
  border-radius: 8px;
  margin: auto;
  padding: 5px; */

  /* width: 33vw; */ /* raunak */
  /* padding: 5px; */ /* raunak */
  border-radius: 8px;
  max-width: 100%;
  margin: auto;
  height: inherit;
  object-fit: fill;
}

.imgText {
  max-width: 100%;
  border-radius: 8px;
  margin: auto;
  padding: 5px;
}
.bothImg{
  max-width: 100%;
  border-radius: 8px;
  height: inherit;
  margin: auto;
  padding: 5px;
}

.tag {
  font-size: 15px;
  font-weight: bold;
  margin: 0 0 5px;
}

.desc {
  font-weight: 300;
  margin: 0;
  line-height: 24px;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 4;
  height: 100%;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}
  
.descMedium {
  font-weight: 300;
  margin: 0;
  line-height: 28px;
  display: flex;
  max-width: 100%;
  -webkit-line-clamp: 4;
  height: 100%;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  /* font-size: 23px; */ /* raunak */
  font-size: 1.5rem;
  align-items: center;
  text-align: center;
  justify-content: center;
  word-break: break-word;
  /* added media query for iphone5 screen */
  @media screen and (device-aspect-ratio: 40/71) {
  font-size:18px;
}
}

.descFontBigWord,
.descSmall {
  font-weight: 300;
  margin: 0;
  line-height: 17px;
  display: flex;
  max-width: 100%;
  -webkit-line-clamp: 4;
  height: 100%;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  /* font-size: 16px; */ /* raunak */
  font-size: 0.8rem;
  align-items: center;
  text-align: center;
  justify-content: center;
  word-break: break-word;
  @media screen and (max-width: 300px) {
    line-height: 12pt;
    font-size: 14px;
  }
}

.descBig {
  font-weight: 300;
  margin: 0;
  display: flex;
  max-width: 100%;
  -webkit-line-clamp: 4;
  height: 100%;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 25px;
  align-items: center;
  width: 100%;
  text-align: center;
  justify-content: center;
  word-break: break-word;
}

.descSmallWoImage {
  font-weight: 300;
  margin: 0;
  line-height: initial;
  display: flex;
  max-width: 100%;
  -webkit-line-clamp: 4;
  height: 100%;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  /* font-size: 22px;  */ /* raunak */
  font-size: 1.3rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  word-break: break-word;
  @media screen and (max-width: 300px) {
    font-size: 18px;
  }
}

.descMediumWoImage {
  font-weight: 300;
  margin: 0;
  line-height: 34px;
  display: flex;
  max-width: 100%;
  -webkit-line-clamp: 4;
  height: 100%;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  /* font-size: 30px;  */ /* raunak */
  font-size: 1.9rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  word-break: break-word;
}

.descBigWoImage {
  font-weight: 300;
  margin: 0;
  line-height: 40px;
  display: flex;
  width: 100%;
  -webkit-line-clamp: 4;
  height: 100%;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  /* font-size: 40px; */ /*raunak*/ 
  font-size: 2.5rem;
  align-items: center;
  justify-content: center;
  text-align: center;
  word-break: break-word;
}
.or {
  font-size: 18px;
  color: #ffffff;
  font-family: "Raleway", sans-serif;
  margin: 5px 0;
}

.itemContainer {
  flex-shrink: 0;
  margin: 0 20px;
  width: calc(100% - 40px);
  /* height: inherit; */ /* raunak */
  height: 100%; 
}

.thisAndThatButton {
  width: 80%;
  background-color: var(--blue) !important;
  border: 1px solid transparent;
  border-radius: 6px 0 0 6px;
  padding: 0 0 0 20%;
  font-size: 18px;
}

.thisAndThatButtonIcon {
  width: 20%;
  background-color: var(--light-blue) !important;
  border: 1px solid transparent;
  border-radius: 0px 6px 6px 0px;
}

/* newly added */
.smashDesc {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 2% 0;
  min-height: fit-content;
  max-height: 24vh;
  max-height: 25%;
}

.tqTextLarge {
  font-size: 1.5rem;
  line-height: 25px;
  position: relative;
  margin: 5% 20px 1% 20px;
  word-break: break-word;
}

.emailText {
  font-size: 1.3rem;
  font-weight: 100;
  margin: 2% 0;
  line-height: 25px;
}

.selectionBox {
  display: flex;
  transition: 0.2s;
  /* height: min(78%, 74vh); */
  min-height: fit-content;
  height: 100%;
  max-height: 71%;
}
